<template>
  <div class="plan" :class="styles[isColor]" v-show="isShow">
    <van-nav-bar
      class="plan-header"
      :title="navTitle"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="plan-table">
      <div class="plans-header">
        <div class="plans-cell" v-for="(item, index) in headers" :key="index">
          <p>{{ item }}</p>
        </div>
      </div>
      <div
        class="plan-lists"
        :dataA="index"
        v-for="(item, index) in lists"
        :key="index"
      >
        <div class="plans-date">
          <div class="plans-date-border">
            <div class="plans-date-box">
              <p>{{ item.date }}</p>
              <p>{{ item.day }}</p>
            </div>
          </div>
        </div>
        <div class="plans-course">
          <div class="plan-list" v-for="(i, idx) in item.list" :key="idx">
            <div class="plans-cell">
              <div class="plans-cell-time">
                <p>{{ i.beginTime }} -</p>
                <p>{{ i.endTime }}</p>
              </div>
            </div>
            <div class="plans-cell">
              <p
                :class="i.sceneTeaching === '1' ? 'cell-is-tap' : ''"
                @click="navTapPoint(i)"
              >
                {{ i.course }}
              </p>
              <p
                class="cell-is-tap file-style"
                @click="navTapFile(i)"
                v-if="i.hasFile"
              >
                附件
              </p>
            </div>
            <div
              class="plans-cell"
              :class="i.hasIntroduce ? 'cell-is-tap' : ''"
              @click="navTapTeacher(i)"
            >
              <p>{{ i.teacher }}</p>
            </div>
          </div>
        </div>
      </div>
      <van-empty description="暂无数据" v-if="lists.length === 0" />
      <van-dialog
        v-model="showFileList"
        title="附件列表"
        confirm-button-text="返回"
      >
        <div class="file-list" v-for="(item, idx) in actions" :key="idx">
          <div class="file-name" @click="openFile(item)">
            {{ item.fileName }}
          </div>
          <div class="file-download" @click="downloadFile(item)">下载</div>
        </div>
      </van-dialog>
    </div>
  </div>
</template>

<script>
import { baseUrl } from "@/service";
/**
 * 表格结构组装好了，但是颜色的分配还需要后端配合才能加下来的开发
 */
import { studentCourse, detailType, fileList } from "@/service";
export default {
  name: "plan",
  data() {
    return {
      showFileList: false,
      actions: [],
      navTitle: "日程安排",
      headers: ["日期", "时间", "内容", "师资"],
      styles: ["four", "blue", "orange"],
      isColor: 0,
      isShow: false,
      lists: [
        // {
        //   date: "1月31日",
        //   day: "星期一",
        //   list: [
        //     { time: "08:00 - 09:00", course: "开班仪式", teacher: "" },
        //     {
        //       time: "16:00 - 18:30",
        //       course:
        //         "现场教学：城市湖泊（河流）生态 修复模式——武汉东湖绿道、内沙湖生态规划设计",
        //       teacher: "李岩星",
        //     },
        //   ],
        // },
        // {
        //   date: "2月1日",
        //   day: "星期二",
        //   list: [
        //     {
        //       time: "08:00 - 09:00",
        //       course: "全媒体语境下水生态保护的新闻 宣传及舆论引导工作",
        //       teacher: "王世梅",
        //     },
        //     {
        //       time: "16:00 - 18:30",
        //       course:
        //         "现场教学：人民至上 生命至上——湖北省抗击新冠肺炎疫情 专题展览",
        //       teacher: "李钰",
        //     },
        //   ],
        // },
        // {
        //   date: "2月2日",
        //   day: "星期三",
        //   list: [
        //     { time: "08:00 - 09:00", course: "开班仪式", teacher: "" },
        //     {
        //       time: "16:00 - 18:30",
        //       course:
        //         "现场教学：城市湖泊（河流）生态 修复模式——武汉东湖绿道、内沙湖生态规划设计",
        //       teacher: "李岩星",
        //     },
        //   ],
        // },
        // {
        //   date: "2月3日",
        //   day: "星期四",
        //   list: [
        //     {
        //       time: "08:00 - 09:00",
        //       course: "全媒体语境下水生态保护的新闻 宣传及舆论引导工作",
        //       teacher: "王世梅",
        //     },
        //     {
        //       time: "16:00 - 18:30",
        //       course:
        //         "现场教学：人民至上 生命至上——湖北省抗击新冠肺炎疫情 专题展览",
        //       teacher: "李钰",
        //     },
        //   ],
        // },
        // {
        //   date: "2月4日",
        //   day: "星期五",
        //   list: [
        //     { time: "08:00 - 09:00", course: "开班仪式", teacher: "" },
        //     {
        //       time: "16:00 - 18:30",
        //       course:
        //         "现场教学：城市湖泊（河流）生态 修复模式——武汉东湖绿道、内沙湖生态规划设计",
        //       teacher: "李岩星",
        //     },
        //   ],
        // },
        // {
        //   date: "2月5日",
        //   day: "星期六",
        //   list: [
        //     {
        //       time: "08:00 - 09:00",
        //       course: "全媒体语境下水生态保护的新闻 宣传及舆论引导工作",
        //       teacher: "王世梅",
        //     },
        //     {
        //       time: "16:00 - 18:30",
        //       course:
        //         "现场教学：人民至上 生命至上——湖北省抗击新冠肺炎疫情 专题展览",
        //       teacher: "李钰",
        //     },
        //   ],
        // },
        // {
        //   date: "2月6日",
        //   day: "星期日",
        //   list: [
        //     { time: "08:00 - 09:00", course: "开班仪式", teacher: "" },
        //     {
        //       time: "16:00 - 18:30",
        //       course:
        //         "现场教学：城市湖泊（河流）生态 修复模式——武汉东湖绿道、内沙湖生态规划设计",
        //       teacher: "李岩星",
        //     },
        //   ],
        // },
        // {
        //   date: "2月7日",
        //   day: "星期一",
        //   list: [
        //     { time: "08:00 - 09:00", course: "开班仪式", teacher: "" },
        //     {
        //       time: "16:00 - 18:30",
        //       course:
        //         "现场教学：城市湖泊（河流）生态 修复模式——武汉东湖绿道、内沙湖生态规划设计",
        //       teacher: "李岩星",
        //     },
        //   ],
        // },
      ],
    };
  },
  created() {
    this.getLists();
    this.getType();
  },
  methods: {
    async getType() {
      // 获取风格接口
      let res = await detailType(
        localStorage.getItem("SID"),
        "sys_course_style"
      );
      this.isColor = parseInt(res[0].dictValue);
    },
    getLists() {
      studentCourse(localStorage.getItem("SID")).then(({ data }) => {
        let list = [];
        for (let i of Object.keys(data)) {
          let month = new Date(i + "").getMonth() + 1;
          let day = new Date(i).getDate();
          let week = new Date(i).getDay();
          let weeks = [
            "星期日",
            "星期一",
            "星期二",
            "星期三",
            "星期四",
            "星期五",
            "星期六",
          ];
          let item = {
            date: `${month}月${day}日`,
            day: weeks[week],
            list: [],
          };
          for (let k of data[i]) {
            item.list.push({
              id: k.id,
              beginTime: k.beginTime,
              endTime: k.endTime,
              time: `${k.beginTime} - ${k.endTime}`,
              course: k.courseName ?? "",
              teacher: k.teacherName ?? "",
              teacherCode: k.teacherCode ?? "",
              content: k.remarks,
              hasFile: k.hasFile,
              hasIntroduce: k.hasIntroduce,
              sceneTeaching: k.sceneTeaching,
              showPopover: false,
            });
          }
          list.push(item);
        }
        this.lists = list;
        this.isShow = true;
      });
    },
    onClickLeft() {
      this.$router.push({
        path: "/home",
      });
    },
    navTapPoint(i) {
      if (i.sceneTeaching === "1") {
        let obj = {
          title: i.course,
          content: i.content,
        };
        localStorage.setItem("pointDetail", JSON.stringify(obj));
        this.$router.push({
          path: "/detail",
          query: {
            type: 3,
          },
        });
      }
    },
    navTapTeacher(i) {
      if (i.hasIntroduce) {
        this.$router.push({
          path: "/teacher",
          query: {
            teacherCode: i.teacherCode,
          },
        });
      }
    },
    async navTapFile(i) {
      let data = await fileList(localStorage.getItem("SID"), i.id);
      this.actions = !data.result ? data : [];
      this.showFileList = true;
    },
    openFile(i) {
      // window.open(baseUrl + '/cms' + i.fileUrl)
      localStorage.setItem("pdfUrl", baseUrl + "/cms" + i.fileUrl);
      this.$router.push({
        path: "/viewer",
      });
    },
    downloadFile(i) {
      window.open(baseUrl + "/cms" + i.fileUrl);
    },
  },
};
</script>

<style lang="less">
.plan {
  min-height: 100vh;
  &.blue {
    .plans-header {
      background: #84c8ff;
    }
    .plan-lists {
      &:nth-child(2n) {
        background: rgba(225, 241, 255, 1);
      }
      &:nth-child(2n + 1) {
        background: rgba(225, 241, 255, 0.55);
      }
    }
  }
  // 四色
  &.four {
    .plan-table {
      .plans-header {
        background: #ededed;
        .plans-cell {
          color: #3f3f3f;
        }
      }
    }
    .plan-lists {
      &:nth-child(4n + 2) {
        background: #e1f1ff;
      }
      &:nth-child(4n + 3) {
        background: #e1fcff;
      }
      &:nth-child(4n + 4) {
        background: #e5ffe1;
      }
      &:nth-child(4n + 5) {
        background: rgba(255, 245, 225, 0.35);
      }
    }
  }
  &.orange {
    border-bottom: 1px solid #fff;
    .plans-header {
      .plans-cell:nth-child(1) {
        background: #ffbf84;
      }
      .plans-cell {
        background: #84c8ff;
      }
    }
    .plan-lists {
      .plans-date {
        background: #fff3e1;
      }
      .plans-course {
        background: #e1f1ff;
      }
    }
  }
  .plan-table {
    box-sizing: border-box;
    .plans-header {
      display: flex;
      border-bottom: 1px solid #fff;
      .plans-cell {
        font-size: 16px;
        color: #fff;
        &:nth-child(1) {
          width: 20%;
          border-right: 1px solid #fff;
        }
        &:nth-child(2) {
          width: 20%;
          border-right: 1px solid #fff;
        }
        &:nth-child(3) {
          width: 40%;
          border-right: 1px solid #fff;
        }
        &:nth-child(4) {
          width: 20%;
          border-right: 1px solid #fff;
        }
      }
    }
    .plan-lists {
      display: flex;
      border-bottom: 1px solid #fff;
      .plans-cell {
        font-size: 15px;
        color: #3f3f3f;
        &-time {
          text-align: center;
          p {
            display: inline-block;
          }
        }
      }
      .plans-date {
        flex: 1;
        &-border {
          height: 100%;
          border-right: 1px solid #fff;
          position: relative;
          overflow: hidden;
          display: flex;
        }
        &-box {
          width: 100%;
          align-self: center;
          p {
            font-size: 15px;
            text-align: center;
          }
        }
      }
      .plans-course {
        flex: 4;
        .plan-list {
          display: flex;
          border-bottom: 1px solid #fff;
          &:nth-last-child(1) {
            border-bottom: 0px solid #fff;
          }
          .cell-is-tap {
            color: #409eff;
            font-weight: bold;
          }
        }
        .plans-cell {
          width: 0;
          word-break: break-all;
          &:nth-child(1) {
            width: 25%;
            border-right: 1px solid #fff;
          }
          &:nth-child(2) {
            width: 50%;
            border-right: 1px solid #fff;
          }
          &:nth-child(3) {
            width: 25%;
            border-right: 1px solid #fff;
          }
        }
      }
    }
    .plans-cell {
      padding: 14px 0px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      p {
        width: 100%;
        text-align: center;
      }
    }
  }
}
.file-list {
  padding: 20px;
  color: #84c8ff;
  display: flex;
  .file-name {
    flex: 1;
  }
  .file-download {
    width: 60px;
  }
}
.file-style {
  width: 100%;
  margin-top: 5px;
}
</style>
